import {debounce} from "../Helpers/utlis";

export default class Faq
{
    constructor()
    {
        this.#faqInteraction()
        this.#faqToggler()
        this.#resize()
    }

    #faqInteraction()
    {
        document.querySelectorAll(".accordion__header").forEach(header => {
            header.addEventListener('click', () => {
                header.classList.toggle("active");
                const body = header.nextElementSibling;

                if (body.style.maxHeight) {
                    body.style.maxHeight = null;
                } else {
                    body.style.maxHeight = body.scrollHeight + "px";
                }
            })
        })
    }

    #faqToggler()
    {
        let faqTogglerButton = document.getElementById('faq-toggler-button')
        let faqHidden = document.getElementById('faq-hidden')
        let showMore = document.getElementById('faq-toggler-button-show-more')
        let showLess = document.getElementById('faq-toggler-button-show-less')

        if(faqTogglerButton && faqHidden && showMore && showLess) {
            faqTogglerButton.addEventListener('click', () => {

                console.log('testfaq')
                faqTogglerButton.classList.toggle('active')

                faqHidden.classList.toggle('open')
                showMore.classList.toggle('hidden')
                showLess.classList.toggle('hidden')
            })
        }
    }

    #resize()
    {
        const container = document.querySelector("#faq-hidden");
        const button = document.getElementById('faq-toggler-button');

        if (!container || !button) {
            return
        }

        const debouncedResize = debounce(function () {
            if (button.classList.contains('open')) {
                container.style.maxHeight = container.scrollHeight + "px"
            }
        }, 250);

        window.addEventListener('resize', debouncedResize);

        button.addEventListener('click', () =>{

            button.classList.toggle("open");
            container.style.maxHeight = container.style.maxHeight ? null : (container.scrollHeight + "px")
        })

        container.querySelectorAll(".accordion__header").forEach(header => {
            header.addEventListener('click', debouncedResize)
        })

        /**
         * Dostosowuje maxHeight contentu accordiona po zmianie rozmiaru okna
         */
        const resizeActiveContainers = () => {
            const activeAccordionContainers = document.querySelectorAll(".accordion__header.active");
            activeAccordionContainers.forEach(header => {
                const body = header.nextElementSibling;

                if (body.style.maxHeight) {
                    body.style.maxHeight = body.scrollHeight + "px";
                }
            });
        }

        window.addEventListener('resize', debounce(resizeActiveContainers, 200));
    }
}