import Swiper from 'swiper/bundle';

export default class Sliders
{
    constructor()
    {
        this.#heroSlider()
        this.#winnersSlider()
    }

    #heroSlider()
    {
        const sliderOptions = {
            loop: true,
            centeredSlides: true,
            effect: "creative",
            creativeEffect: {
                prev: {
                    translate: [0, 0, -400],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            },
            slidesPerView: 1,
            allowTouchMove: false,
            grabCursor: false,
            autoplay: {
                delay: 8000,
            }
        }

        this.#loadSlider("hero-slider", sliderOptions);
    }

    #winnersSlider()
    {
        const sliderOptions = {
            loop: true,
            centeredSlides: true,
            slidesPerView: 1,
            allowTouchMove: true,
            grabCursor: false,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        }

        this.#loadSlider("winners-slider", sliderOptions);
    }

    #loadSlider(sliderId, sliderOptions)
    {
        if(document.getElementById(sliderId) === null)
            return

        if(this.checkItemsCount(sliderId)) {
            if(sliderOptions === null || typeof sliderOptions === 'undefined')
                sliderOptions = {}

            return new Swiper('#' + sliderId, sliderOptions);
        }

        return false
    }

    checkItemsCount(sliderId)
    {
        const itemsCount = parseInt(document.getElementById(sliderId).dataset.itemsCount)

        return !(typeof itemsCount === 'undefined' || itemsCount === 0 || itemsCount === 1)
    }
}