const isInViewport = (elem) => {
    const bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

export const scrollToElement = (element, offsetTop) => {
    offsetTop = typeof offsetTop !== 'undefined' ? offsetTop : 0;
    var top = element !== null ? element.getBoundingClientRect().top : 0,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    top += scrollTop;

    window.scroll({ top: top + offsetTop , left: 0, behavior: 'smooth' });
}

export const debounce = (callback, wait) => {
    let timeout;
    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
}

export function throttle(func, limit) {
    let inThrottle;
    return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
}

export function addClass(el, className) {
    if (el === null) return;

    if (el.classList)
        el.classList.add(className);
    else {
        if (typeof el.className === 'object') { // obsługa svgów w IE
            el.className.baseVal += ' ' + className;
        } else {
            el.className += ' ' + className;
        }
    }
}

export function removeClass(el, className) {
    if (el === null) return;

    if (el.classList)
        el.classList.remove(className);
    else {
        var regex = new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi');

        if (typeof el.className === 'object') { // obsługa svgów w IE
            el.className.baseVal = el.className.baseVal.replace(regex, ' ');
        } else {
            el.className = el.className.replace(regex, ' ');
        }
    }
}

export function hasClass(el, className) {
    if (el.classList)
        return el.classList.contains(className);
    else {
        var classes = el.className;

        if (typeof el.className === 'object') { // obsługa svgów w IE
            classes = el.className.baseVal;
        }

        return new RegExp('(^| )' + className + '( |$)', 'gi').test(classes);
    }
}
