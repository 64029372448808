import {addClass, removeClass, scrollToElement} from "../Helpers/utlis";
import * as bootstrap from 'bootstrap'

export default class Menu {
    constructor() {
        this.initScroll()
        this.initScrollOnClick()
        this.initNavbarScrolled()
        this.initAddClassToNavbarOnOpen()
    }

    initScroll() {
        if (window.location.hash) {
            scrollToElement(document.getElementById(window.location.hash.substr(1)), -50)
            history.pushState(null, null, window.location.hash)
        }
    }

    initScrollOnClick() {
        const menu = document.getElementById('menu')

        document.querySelectorAll('#menu .nav-link:not(.dropdown-toggle)').forEach(el => {
            el.addEventListener('click', function (e) {

                const hash = this.hash

                if (hash) {
                    e.preventDefault()
                    scrollToElement(document.getElementById(hash.substr(1)), -70)
                    history.pushState(null, null, hash)
                }

                if (menu.classList.contains('show')) {
                    const bsCollapse = new bootstrap.Collapse(menu);
                    bsCollapse.hide();
                }
            })
        })
    }

    initNavbarScrolled() {
        const navbar = document.getElementById('navbar'),
            scrollClass = 'scrolled'

        if (!navbar)
            return

        const scrollHandle = () => (window.pageYOffset > 50) ? addClass(navbar, scrollClass) : removeClass(navbar, scrollClass);

        window.addEventListener('load', () => {
            window.addEventListener('scroll', scrollHandle)
            scrollHandle()
        })
    }

    initAddClassToNavbarOnOpen() {
        const menu = document.getElementById('menu')
        const navbar = document.getElementById('navbar')
        const openClass = 'open';

        if (!navbar || !menu) return false;

        menu.addEventListener('show.bs.collapse', function () {
            navbar.classList.add(openClass);
            document.body.classList.add(openClass)
            console.log('testopen')
        })

        menu.addEventListener('hide.bs.collapse', function () {
            navbar.classList.remove(openClass);
            document.body.classList.remove(openClass)
            console.log('test')
        })
    }
}
