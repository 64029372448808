
export default class Winners
{
    constructor()
    {
        this.winnersTabs()
        this.winnersDateTabs()
    }

    winnersTabs() {
        const tabsElements = document.querySelectorAll('.winners__tab');
        const contentElements = document.querySelectorAll('.winners__container');

        if (tabsElements.length === 0 || contentElements.length === 0)
            return

        tabsElements.forEach(tabElement => {
            tabElement.addEventListener('click', () => {
                const title = tabElement.getAttribute('data-title');

                // Dodaj lub usuń klasę 'active' na elementach .win-dates__date
                tabsElements.forEach(el => {
                    if (el.getAttribute('data-title') === title) {
                        el.classList.add('active');
                    } else {
                        el.classList.remove('active');
                    }
                });

                // Dodaj lub usuń klasę 'active' na elementach .winners__list
                contentElements.forEach(el => {
                    if (el.getAttribute('data-title') === title) {
                        el.classList.add('active');
                    } else {
                        el.classList.remove('active');
                    }
                });
            });
        });
    }

    winnersDateTabs() {
        const dateElements = document.querySelectorAll('.win-dates__date');
        const listElements = document.querySelectorAll('.winners__list');

        if (dateElements.length === 0 || listElements.length === 0)
            return

        dateElements.forEach(dateElement => {
            dateElement.addEventListener('click', () => {
                const date = dateElement.getAttribute('data-date');

                // Dodaj lub usuń klasę 'active' na elementach .win-dates__date
                dateElements.forEach(el => {
                    if (el.getAttribute('data-date') === date) {
                        el.classList.add('active');
                    } else {
                        el.classList.remove('active');
                    }
                });

                // Dodaj lub usuń klasę 'active' na elementach .winners__list
                listElements.forEach(el => {
                    if (el.getAttribute('data-date') === date) {
                        el.classList.add('active');
                    } else {
                        el.classList.remove('active');
                    }
                });
            });
        });
    }
}