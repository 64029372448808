import Cookies from 'js-cookie'

export default class CookiesInfo
{
    constructor()
    {
        this.initInfo()
    }

    initInfo()
    {
        let cookiesInfo = document.getElementById('cookies-info');

        if(!cookiesInfo)
            return

        const showCookieInfoLayer = () => {
            if (Cookies.get('cookie_info') !== 'set')
                setTimeout(() => cookiesInfo.classList.add('show'), 2000)
        }

        showCookieInfoLayer()

        cookiesInfo.addEventListener('click', () => {
            Cookies.set('cookie_info', 'set', {
                expires: 365,
                path: '/'
            })
            cookiesInfo.classList.remove('show')
        })
    }
}