import '../styles/main.scss'
import 'bootstrap';
import Menu from "./Classes/Menu";
import Faq from "./Classes/Faq";
import AOS from 'aos'
import CookiesInfo from "./Classes/Cookies";
import Winners from "./Classes/Winners";
import Sliders from "./Classes/Sliders";

document.addEventListener('DOMContentLoaded', () => {
    new Front()
})

class Front
{
    constructor()
    {
        new Menu();
        new Faq();
        new CookiesInfo();
        new Winners()
        new Sliders()

        this.#aosInit()
        this.#initEmailLinks();
        this.#scrollUpButton();
        this.#initMapOverlay()
    }

    /**
     * Inicjalizacja biblioteki AOS odpowiadającej za animacje elementów HTML
     */
    #aosInit()
    {
        AOS.init({
            once: true,
            duration: 600,
        })
    }

    /**
     * Wyświetla linki mailto przy mailach zabezpieczonych przez filterText.
     */
    #initEmailLinks() {
        const elements = document.querySelectorAll('.email-link')

        if (elements.length === 0) return

        elements.forEach(function (el) {
            let email = el.dataset.a + '@' + el.dataset.b

            el.setAttribute('href', "mailto:" + email)

            el.querySelectorAll('noscript').forEach(noscript => noscript.remove())

            // domyslnie dodajemy email do elementu
            let addEmailToContent = true

            if (el.dataset.addEmailToContent === '0') {
                addEmailToContent = false
            }

            if (addEmailToContent) {
                el.insertAdjacentText('beforeend', email)
            }
        });
    }

    /**
     * Inicjalizacja przycisku scroll Up
     */
    #scrollUpButton()
    {
        const scrollToTopBtn = document.getElementById('scroll-up');

        if (!scrollToTopBtn)
            return
        function toggleScrollToTopButton() {
            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                scrollToTopBtn.classList.add('show');
            } else {
                scrollToTopBtn.classList.remove('show');
            }
        }

        function scrollToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }

        window.addEventListener('scroll', toggleScrollToTopButton);
        scrollToTopBtn.addEventListener('click', scrollToTop);
    }

    #initMapOverlay() {
        const mapOverlay = document.querySelector('.map-overlay');

        if (mapOverlay) {
            mapOverlay.addEventListener('click', () => {
                mapOverlay.classList.add('hidden');
            })
        }
    }
}